import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery } from 'gatsby'

function SEOpage({
  description,
  lang,
  meta,
  keywords,
  title,
  slug,
  image,
  metaData,
}) {
  const metaDescription = "Outspoke is a supply management platform designed to connect and empower the cannabis industry.";
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          property: 'og:title',
          content: title,
          type: "text/javascript"
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: '//outspoke.io/'+image,
          type: "text/javascript"
        },
        {
          property: 'og:url',
          content: '//outspoke.io/' + slug,
          type: "text/javascript"
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'Outspoke.io',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    >
      <link rel="canonical" href={'https://outspoke.io/' + slug} />
      <script src="https://kit.fontawesome.com/6702447681.js"></script>
      <link
        rel="stylesheet"
        type="text/css"
        href="//fonts.googleapis.com/css?family=Open+Sans"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

    </Helmet>
  )
}

SEOpage.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEOpage.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEOpage
